import axios from 'axios';
// config
import { HOST_API } from '../config';
import { store } from 'src/redux/store';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers.Accept = 'application/json';
  return config;
}, error => {
  return Promise.reject(error);
});
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const webHookAxiosInstace = axios.create({
  baseURL: process.env.REACT_APP_WEBHOOK_API_KEY,
});

webHookAxiosInstace.interceptors.request.use(function (config) {
  let token = store.getState()?.auth?.user?.data?.token;
  config.headers.Accept = 'application/json';
//   if (token) {
//     config.headers.Authorization = 'Bearer ' + token;
// }
  return config;
}, error => {
  return Promise.reject(error);
});
webHookAxiosInstace.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
export default axiosInstance;
